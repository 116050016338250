import "regenerator-runtime/runtime";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "remix";
import type { MetaFunction, LinksFunction } from "remix";
import designStyle from './styles/tailwind.css';

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "Datos SocioAmbientales",
  viewport: "width=device-width,initial-scale=1",
});

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: designStyle,
    },
    {
      rel: 'stylesheet',
      href: 'https://use.typekit.net/wdd6isw.css',
    },
  ];
};

export default function App() {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        {typeof document === "undefined"
          ? "__STYLES__"
          : null}
      </head>
      <body style={{background: '#f3f4f6'}}>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        
      </body>
    </html>
  );
}
